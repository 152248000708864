/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Formik, Form, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Callout, css, DirectionalHint, PrimaryButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from '@uifabric/react-hooks';
import {
  EnvironmentRequestFormValue,
  environmentRequestFormValueValidationSchema,
  initialEnvironmentRequestFormValue
} from './EnvironmentRequestFormValue';
import { EnvironmentRequestFormModel } from './EnvironmentRequestFormModel';
import { EnvironmentRequestBasicInfoForm } from './Forms/EnvironmentRequestBasicInfoForm';
import { EnvironmentRequestPlatformInformationForm } from './Forms/EnvironmentRequestPlatformInformationForm';
import { EnvironmentRequestComplianceInformationForm } from './Forms/EnvironmentRequestComplianceInformationForm';
import Stepper from '../../Stepper/Stepper';
import { EnvironmentRequestFormStyle, EnvironmentRequestStepperStyle } from './Forms/EnvironmentRequestForm.styles';
import { submitEnvironmentRequest } from '../../../shared/store/actions';
import { IRootState } from '../../../shared/store/IRootState';
import { SuccessBanner } from '../../SuccessBanner';
import { LoadingState } from '../../../shared/models/ILoading';
import { getCachedUser } from '../../../msalConfig';
import { ICheckMemberInSGResponse, checkMemberInSGResponseInitiaValues } from '../../../shared/models/Environment/ICheckMemberInSGResponse';

const steps = ['Basic Information', 'Platform Information', 'Compliance Information'];
const { formId, formField } = EnvironmentRequestFormModel;

const _renderStepContent = (step: number, acceptTerm: boolean, handleAcceptTermChange: () => void, isMemberOfSecurityGroupValid: boolean, setIsMemberOfSecurityGroupValid: React.Dispatch<React.SetStateAction<boolean>>, isD365EnvironmentVaild: boolean, setIsD365EnvironmentVaild: React.Dispatch<React.SetStateAction<boolean>>, isMemberOfSecurityGroupResponse: ICheckMemberInSGResponse, setIsMemberOfSecurityGroupResponse: React.Dispatch<React.SetStateAction<ICheckMemberInSGResponse>>) => {
  switch (step) {
    case 0:
      return (
        <EnvironmentRequestBasicInfoForm
          formField={formField}
          formId={formId}
          acceptTerm={acceptTerm}
          handleAcceptTermChange={handleAcceptTermChange}
        />
      );
    case 1:
      return (
        <EnvironmentRequestPlatformInformationForm
          formField={formField}
          formId={formId}
          acceptTerm={acceptTerm}
          handleAcceptTermChange={handleAcceptTermChange}
          isMemberOfSecurityGroupValid={isMemberOfSecurityGroupValid}
          setIsMemberOfSecurityGroupValid={setIsMemberOfSecurityGroupValid}
          isD365EnvironmentVaild={isD365EnvironmentVaild}
          setIsD365EnvironmentVaild={setIsD365EnvironmentVaild}
          isMemberOfSecurityGroupResponse={isMemberOfSecurityGroupResponse}
          setIsMemberOfSecurityGroupResponse={setIsMemberOfSecurityGroupResponse}
        />
      );
    case 2:
      return (
        <EnvironmentRequestComplianceInformationForm
          formField={formField}
          formId={formId}
          acceptTerm={acceptTerm}
          handleAcceptTermChange={handleAcceptTermChange}
        />
      );
    default:
      return <div>Not Found</div>;
  }
};

export const EnvironmentRequestForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [isMemberOfSecurityGroupValid, setIsMemberOfSecurityGroupValid] = useState(true);
  const [isD365EnvironmentVaild, setIsD365EnvironmentVaild] = useState(true);
  const [isMemberOfSecurityGroupResponse, setIsMemberOfSecurityGroupResponse] = useState<ICheckMemberInSGResponse>(checkMemberInSGResponseInitiaValues);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

  const submitEnvironmentRequestState = useSelector((state: IRootState) => state.submitEnvironmentRequestState);

  const isLastStep = activeStep === steps.length - 1;
  function _handleAcceptTermChange() {
    setAcceptTerm(!acceptTerm);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  initialEnvironmentRequestFormValue.requestorEmail = getCachedUser()?.username.toString();

  const dispatch = useDispatch();
  const submitFormCallback = React.useCallback(
    (values: EnvironmentRequestFormValue) => dispatch(submitEnvironmentRequest(values)),
    [dispatch]
  );

  function modifyUrl(values: EnvironmentRequestFormValue): EnvironmentRequestFormValue {
    if (values.CDSidentifier) {
      const newValues = {
        ...values,
        CDSinstanceURL: values.CDSidentifier + '.crm.dynamics.com'
      } as EnvironmentRequestFormValue;
      return newValues;
    }
    return values;
  }

  function _submitForm(values: EnvironmentRequestFormValue, _: FormikHelpers<EnvironmentRequestFormValue>) {
    const modifiedValues = modifyUrl(values);
    submitFormCallback(modifiedValues);
  }

  function _handleSubmit(values: EnvironmentRequestFormValue, actions: FormikHelpers<EnvironmentRequestFormValue>) {
    if (isLastStep) {
      actions.setSubmitting(true);
      _submitForm(values, actions);
    } else if(activeStep === 1 && isMemberOfSecurityGroupValid === false){
      actions.setTouched({});
      actions.setSubmitting(false);
    }else if(activeStep === 1 && isD365EnvironmentVaild === false){ // If D365 and Sandbox, submit button will disable
      actions.setTouched({});
      actions.setSubmitting(false);
    }else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  
  function _handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  
  const stepsArray = ['Basic Information', 'Platform Information', 'Compliance Information'];
  return (
    <>
      <div className={css(EnvironmentRequestStepperStyle.wrapper)}>
        <Stepper steps={stepsArray} isVertical={false} currentStepNumber={activeStep} />
      </div>
      <Formik
        initialValues={initialEnvironmentRequestFormValue}
        validationSchema={environmentRequestFormValueValidationSchema}
        onSubmit={_handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {() => {
          if (submitEnvironmentRequestState.loadingState === LoadingState.loaded) {
            return <SuccessBanner requestName='Environment' newRequest='/environment-request' managerAlias={submitEnvironmentRequestState.environmentRequest?.approverEmail}/>;
          }
          return (
            <Form id={formId} className={css(EnvironmentRequestFormStyle.formWrapper)} onKeyDown={_handleKeyDown}>
              <div className={css('Form-Content', EnvironmentRequestFormStyle.formContent)}>
                {_renderStepContent(activeStep, acceptTerm, _handleAcceptTermChange, isMemberOfSecurityGroupValid, setIsMemberOfSecurityGroupValid, isD365EnvironmentVaild, setIsD365EnvironmentVaild, isMemberOfSecurityGroupResponse, setIsMemberOfSecurityGroupResponse)}
              </div>

              <div className={'Form-Buttons'} style={{ display: 'flex', gap: '12px', marginTop: '16px' }}>
                {activeStep !== 0 && <PrimaryButton onClick={_handleBack}>Back</PrimaryButton>}
                <div className={'Submit Button'}>
                  <PrimaryButton type="submit" disabled={submitEnvironmentRequestState.isLoading} id="next-button" onClick={()=>{
                    if(!isMemberOfSecurityGroupValid){
                      toggleIsCalloutVisible();
                    }
                    }}>
                    {isLastStep ? 'Submit' : 'Next'}
                  </PrimaryButton>
                  {isCalloutVisible ? (
                    <Callout
                      ariaLabelledBy={'securitygroup-callout-label'}
                      ariaDescribedBy={'securitygroup-callout'}
                      role="dialog"
                      className={css(EnvironmentRequestFormStyle.calloutDialog)}
                      target={`#next-button`}
                      onDismiss={toggleIsCalloutVisible}
                      directionalHint={DirectionalHint.rightCenter}
                      setInitialFocus
                    >
                      <Text block variant="medium" id={'callout-text-id'}>
                        Security Group must be validated before proceeding to next page
                      </Text>
                    </Callout>
                  ) : null}
                  {submitEnvironmentRequestState.isLoading && (<Spinner size={SpinnerSize.xSmall} label={'Submitting request...'} />)}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
