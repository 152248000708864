export const ENV_CONST = {
  ApiStatus: {
    InViolation: 'In Violation',
    Deleted: 'System Deleted',
    Quarantined: 'Quarantined',
    RemoveFromQuarantine: 'Compliant',
    Compliant: 'Compliant'
  },
  StatusStyle: {
    InViolation: 'markNonCompliant',
    Deleted: 'markDeleted',
    Quarantined: 'markQuarantined',
    RemoveFromQuarantine: 'markCompliant',
    Compliant: 'markCompliant'
  },
  ActionLabel: {
    InViolation: 'Remediate',
    Deleted: 'Restore',
    Quarantined: 'Unquarantine',
    RemoveFromQuarantine: 'Unquarantine',
    Compliant: 'Compliant',
    View: 'View'
  }
};

export const ENV_API_STATE = {
  IN_VIOLATION: 'InViolation',
  DELETED: 'Deleted',
  QUARANTINED: 'Quarantined',
  REMOVE_FROM_QUARANTINE: 'RemoveFromQuarantine',
  COMPLIANT: 'Compliant'
};

export const ADMIN_ENV_URL = 'https://admin.powerplatform.microsoft.com/environments/environment';
export const ADMIN_DELETED_ENV_URL = 'https://admin.powerplatform.microsoft.com/environments/deleted';
