import {
  ApplicationInsights,
  IMetricTelemetry,
  IExceptionTelemetry,
  IEventTelemetry,
  ITraceTelemetry,
  IPageViewTelemetry
} from '@microsoft/applicationinsights-web';
import { ITelemetryConfig } from './TelemetryService.types';
import { flatProperties } from './TelemetryUtils';

export namespace TelemetryService {
  let appInsights: ApplicationInsights;

  // A flag to determine whether or not to send telemetry to dev console
  const LOG_TELEMETRY_TO_CONSOLE = process.env.NODE_ENV === 'development';

  /**
   * Initializes the TelemetryService. Don't call any other TelemetryService functions before this one.
   * @param config the config
   */
  export function initialize(config: ITelemetryConfig) {
    if (!config.InstrumentationKey) {
      throw new Error('Instrumentation key not provided');
    }

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.InstrumentationKey
      }
    });

    appInsights.loadAppInsights();
  }

  /**
   * Log metric
   * Typically used to send regular reports of performance indicators.
   * @param metric            A string that identifies the metric.
   * @param customProperties  additional properties to send with the metric.
   */
  export function trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: unknown }) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Metric: ${metric}, ${flatProperties(customProperties)}`);
    } else {
      appInsights.trackMetric(metric, customProperties);
    }
  }

  /**
   * Page Track
   * Typically used to identify which pages in the application are being viewed.
   * @param event            To identify the prooperties of the page
   */
  export function trackPageView(event: IPageViewTelemetry) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Metric: ${event}`);
    } else {
      appInsights.trackPageView(event);
    }
  }

  /**
   * Log an exception you have caught.
   * @param exception The exception to log.
   */
  export function trackException(exception: IExceptionTelemetry) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log('[Telemetry] Log Exception: ', exception);
    } else {
      appInsights.trackException(exception);
    }
  }

  /**
   * Log a user action or other occurrence.
   * @param event             the TelemetryEvent to send
   * @param customProperties  Optional additional properties on the event
   */
  export function trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: unknown }) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Event: ${event}, ${flatProperties(customProperties)}`);
    } else {
      appInsights.trackEvent(event, customProperties);
    }
  }

  /**
   * Log a diagnostic message.
   * @param trace             A diagnostic trace to log
   * @param customProperties  Optional additional properties of form map[string, string] - used to filter traces in the portal.Defaults to empty.
   */
  export function trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: unknown }) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Trace: ${trace} ${flatProperties(customProperties)}`);
    } else {
      appInsights.trackTrace(trace, customProperties);
    }
  }

  /**
   * to see data sent immediately
   */
  export function sendTelemetryNow() {
    appInsights.flush();
  }
}
