import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import { css, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { submitMailboxSSSRequest } from '../../../shared/store/actions';
import { MailboxSSSRequestFormModel } from './MailboxSSSRequestFormModel';
import { MailboxSSSRequestForm } from './Form/MailboxSSSRequestForm';
import { MailboxSSSRequestFormStyle } from './Form/MailboxSSSRequestForm.styles';
import {
  mailboxSSSRequestFormValueValidationSchema,
  initialMailboxSSSRequestFormValue,
  MailboxSSSRequestFormValues
} from './MailboxSSSRequestFormValues';
import { LoadingState } from '../../../shared/models/ILoading';
import { SuccessBanner } from '../../SuccessBanner';
import { getCachedUser } from '../../../msalConfig';

const { formId, formField } = MailboxSSSRequestFormModel;

export const MailboxSSSRequestForms = (props: any) => {
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const dispatch = useDispatch();
  const submitFormCallback = (values: MailboxSSSRequestFormValues) => dispatch(submitMailboxSSSRequest(values));

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  initialMailboxSSSRequestFormValue.requesterEmail = getCachedUser()?.username.toString();

  useEffect(() => {
    if (props.submitMailboxSSSRequestState.loadingState === LoadingState.loaded) 
      setShowSuccessPage(true);
  }, [props.submitMailboxSSSRequestState.loadingState]);

  const isDisabled = (): boolean => {
    return props.submitMailboxSSSRequestState.loadingState === LoadingState.loading;
  };

  const handleSubmit = (values: MailboxSSSRequestFormValues, actions: FormikHelpers<MailboxSSSRequestFormValues>) => {
    actions.setSubmitting(true);
    submitFormCallback(values);
  };

  const handleAcceptTermChange = () => {
    setAcceptTerm(!acceptTerm);
  };
  return showSuccessPage ? (
    <SuccessBanner requestName="Mailbox SSS" newRequest="/mailbox-request/create" />
  ) : (
    <Formik
      initialValues={initialMailboxSSSRequestFormValue}
      validationSchema={mailboxSSSRequestFormValueValidationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      <Form id={formId}>
        <MailboxSSSRequestForm
          formField={formField}
          formId={formId}
          acceptTerm={acceptTerm}
          handleAcceptTermChange={handleAcceptTermChange}
        />
        <div className={css(MailboxSSSRequestFormStyle.submit)}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <PrimaryButton type="submit" disabled={isDisabled()}>
              {isDisabled() ? 'Submitting' : 'Submit'}
            </PrimaryButton>
            {isDisabled() && <Spinner size={SpinnerSize.medium} />}
          </Stack>
        </div>
      </Form>
    </Formik>
  );
};
