import * as React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@uifabric/react-hooks';
import { IEnvironmentAttestationPanelProps } from './EnvironementAttestationPannel.model';
import { EnvironmentAttestationForm } from '../../../../components/Forms/EnvironmentAttestationForm/EnvironmentAttestation';

export const EnvironmentAttestationPanel: React.FunctionComponent<IEnvironmentAttestationPanelProps> = (props) => {
  const { selectedEnvironmentId, btnText, attestationDetails, updateViolationState } = props;
  const [isOpen, { setTrue: openAttestationPanel, setFalse: dismissPanel }] = useBoolean(false);

  const dismissEnvironmentModal = () => {
    dismissPanel();
  };

  return (
    <div style={{ lineHeight: '55px' }}>
      <PrimaryButton text={btnText} onClick={openAttestationPanel} style={{ width: '130px' }} />
      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        headerText="Environment Attestation Form"
      >
        <EnvironmentAttestationForm
          environmentId={selectedEnvironmentId || ''}
          attestationDetails={attestationDetails}
          onSubmitCallback={dismissEnvironmentModal}
          updateViolationState={updateViolationState}
        />
      </Panel>
    </div>
  );
};
